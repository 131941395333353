import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createMember } from "../../Services/Member/memberService";
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css";
import swal from 'sweetalert';
import { MicrosoftLoginAgent } from "../../Services/Authentication/msal";
import {
  Card,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../CSS/Registration.css';
import { useApp } from "../../Contexts/appContext";
import { Spinner } from "../Common/Spinner";
import Autocomplete from '@mui/material/Autocomplete';
import Footer from "../FooterPages/Footer.js";
import AppBar from "../NavBar/AppBar.js";

const formStyle = {
  fontFamily: 'DM Sans',
};

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 30,
    padding: theme.spacing(2),
    maxWidth: 400,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

export default function RegistrationForm() {
  const classes = useStyles();

  const navigate = useNavigate();
  const [account, setAccount] = useState(MicrosoftLoginAgent.getAccount());
  const { setUserType, metaData, isLoadingMetaData } = useApp();
  const [userFirstName, setUserFirstName] = useState('');
  const [userInitials, setUserInitials] = useState('');
  const [userCountry, setUserCountry] = useState('');
  const [userCreatedBy, setUserCreatedBy] = useState('');
  const [userReligion, setUserReligion] = useState('');
  const [userCommunity, setUserCommunity] = useState('');
  const [userResStatus, setUserResStatus] = useState('');
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [userDOB, setUserDOB] = useState('');
  const [userGender, setUserGender] = useState('');

  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState();
  const [initialsErrorMessage, setInitialsErrorMessage] = useState();
  const [countryErrorMessage, setCountryErrorMessage] = useState();
  const [createdByErrorMessage, setCreatedByErrorMessage] = useState();
  const [resStatusErrorMessage, setResStatusErrorMessage] = useState();
  const [PhoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState();
  const [dOBErrorMessage, setDOBErrorMessage] = useState();
  const [genderErrorMessage, setGenderErrorMessage] = useState();
  const [religionErrorMessage, setReligionErrorMessage] = useState();
  const [communityErrorMessage, setCommunityErrorMessage] = useState();
  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/;
  const characterRegExp = /^[a-zA-Z]+$/;
  const initialsRegExp = /^[a-zA-Z]*$/;
  const RequiredErrror = 'This field is required';
  const today = new Date();

  const navigateTosecondstep = async () => {
    const enteredDate = new Date(userDOB);
    let age = today.getFullYear() - enteredDate.getFullYear();
    const monthDiff = today.getMonth() - enteredDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < enteredDate.getDate())) {
      age--;
    }
    setCountryErrorMessage(userCountry === '' ? RequiredErrror : '');
    setFirstNameErrorMessage(
      userFirstName === '' ? RequiredErrror : !characterRegExp.test(userFirstName) ? 'Only alphabetic characters allowed' : ''
    );
    setInitialsErrorMessage(
      !initialsRegExp.test(userInitials) ? 'Only alphabetic characters allowed' : ''
    );
    setCreatedByErrorMessage(userCreatedBy === '' ? RequiredErrror : '');
    setReligionErrorMessage(userReligion === '' ? RequiredErrror : '');
    setCommunityErrorMessage(userCommunity === '' ? RequiredErrror : '');
    setDOBErrorMessage(userDOB === '' ? RequiredErrror : (age < 18 ? 'You must be at least 18 years old' : ''));
    setGenderErrorMessage(userGender === '' ? RequiredErrror : '');
    setPhoneNumberErrorMessage(
      userPhoneNumber === '' ? RequiredErrror :
        !phoneRegExp.test(userPhoneNumber) ? 'Invalid phone number' :
          userPhoneNumber.length < 7 ? 'Phone number must be at least 7 digits with country code' :
            userPhoneNumber.length > 15 ? 'Phone number must be at most 15 digits with country code' :
              ''
    );
    setResStatusErrorMessage(userResStatus === '' ? RequiredErrror : '');
    const data = {
      genderId: userGender,
      dob: userDOB,
      location: {
        countryId: userCountry,
        residentialStatusId: userResStatus,
        religionId: userReligion,
        communityId: userCommunity
      },
      phoneNumber: userPhoneNumber,
      createdById: userCreatedBy,
      firstName: userFirstName,
      surname: userInitials,
    };
    if (
      data.country === '' || data.firstName === '' ||
      data.createdById === '' ||
      data.location.religionId === '' ||
      data.location.communityId === '' ||
      (age < 18) ||
      data.dob === '' ||
      data.genderId === '' ||
      !initialsRegExp.test(data.surname) ||
      data.phoneNumber === '' ||
      !characterRegExp.test(data.firstName) || userPhoneNumber.length > 15 || userPhoneNumber.length < 7 ||
      data.location.residentialStatusId === ''
    ) {
      return;
    }
    try {
      const response = await createMember(data);
      if (response.status === 201) {
        setUserType("NEW_REGISTERED_MEMBER");
        swal({
          text: "Registered",
          icon: 'success',
          buttons: {
            cancel: 'Close',
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        }).then(async () => {
          navigate("/verifyemail");
        });
      } else {
        console.log("error", response.data.errors);
        swal({
          text: 'Registration unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      swal({
        text: 'Registration unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  const onLogout = async () => {
    const successfulLogout = await MicrosoftLoginAgent.logout();
    if (successfulLogout) {
      const account = MicrosoftLoginAgent.getAccount();
      setAccount(account);
      window.location.replace(process.env.REACT_APP_REDIRECT_LOGOUT_URI);
    }
  };

  return (
    <>
      {isLoadingMetaData ? (<Spinner></Spinner>) : (
        <div style={{minHeight: '100vh'}}>
          <AppBar />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card className="cardContent" style={{ padding: '20px', marginBottom: '50px', borderRadius: '15px' }}>
              <Typography className="topic" variant="h6" gutterBottom style={{ fontFamily: 'DM Sans' }}>
                Registration
              </Typography>
              <Grid container spacing={2} style={formStyle}>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    label="Email"
                    defaultValue={account.idTokenClaims?.email}
                    fullWidth
                    disabled
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <TextField
                    label="First Name"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    required
                    onChange={(e) => {
                      const value = e.target.value;
                      setUserFirstName(value);
                      setFirstNameErrorMessage(
                        value === '' ? RequiredErrror : !characterRegExp.test(value) ? 'Only alphabetic characters allowed' : ''
                      );
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{firstNameErrorMessage}</p>
                  <TextField
                    label="Initials"
                    fullWidth
                    onChange={(e) => {
                      const value = e.target.value;
                      setUserInitials(e.target.value);
                      setInitialsErrorMessage(
                        !initialsRegExp.test(value) ? 'Only alphabetic characters allowed' : ''
                      );
                    }}
                    inputProps={{ maxLength: 1 }}
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{initialsErrorMessage}</p>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setUserCountry(newValue.id);
                        setCountryErrorMessage(newValue.id === '' ? RequiredErrror : '');
                      }} //newValue.id gives you the id of the selected option
                      options={metaData?.countries}
                      required
                      clearIcon={null}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Country *" />}
                    />
                    <p style={{ color: 'red' }}>{countryErrorMessage}</p>
                  </FormControl>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setUserResStatus(newValue.id)
                        setResStatusErrorMessage(newValue.id === '' ? RequiredErrror : '');
                      }}
                      options={metaData?.residentialStatuses}
                      required
                      clearIcon={null}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Residential Status *" />}
                    />
                    <p style={{ color: 'red' }}>{resStatusErrorMessage}</p>
                  </FormControl>
                  <InputLabel style={{ fontFamily: 'DM Sans' }}>Phone Number *</InputLabel>
                  <PhoneInput style={{ marginTop: '5px' }}
                    country='lk'
                    onChange={(phoneNumber) => {
                      setUserPhoneNumber(phoneNumber);
                      setPhoneNumberErrorMessage(
                        userPhoneNumber === '' ? RequiredErrror :
                          !phoneRegExp.test(userPhoneNumber) ? 'Invalid phone number' :
                            userPhoneNumber.length < 7 ? 'Phone number must be at least 7 digits with country code' :
                              userPhoneNumber.length > 15 ? 'Phone number must be at most 15 digits with country code' :
                                ''
                      );
                    }}
                    inputExtraProps={{
                      name: "phoneNumber",
                      id: "phoneNumber",
                      required: true,
                    }}
                  />
                  <p style={{ color: 'red' }}>{PhoneNumberErrorMessage}</p>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setUserCreatedBy(newValue.id)
                        setCreatedByErrorMessage(newValue.id === '' ? RequiredErrror : '');
                      }}
                      options={metaData?.creators}
                      required
                      clearIcon={null}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Created By *" />}
                    />
                    <p style={{ color: 'red' }}>{createdByErrorMessage}</p>
                  </FormControl>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setUserReligion(newValue.id)
                        setReligionErrorMessage(newValue.id === '' ? RequiredErrror : '');
                      }}
                      options={metaData?.religions}
                      required
                      clearIcon={null}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Religion *" />}
                    />
                    <p style={{ color: 'red' }}>{religionErrorMessage}</p>
                  </FormControl>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setUserCommunity(newValue.id)
                        setCommunityErrorMessage(newValue.id === '' ? RequiredErrror : '');
                      }}
                      options={metaData?.communities}
                      required
                      clearIcon={null}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Community *" />}
                    />
                    <p style={{ color: 'red' }}>{communityErrorMessage}</p>
                  </FormControl>
                  <TextField
                    id="dob"
                    required
                    onChange={(e) => {
                      setUserDOB(e.target.value);
                      const enteredDate = new Date(e.target.value);
                      let age = today.getFullYear() - enteredDate.getFullYear();
                      const monthDiff = today.getMonth() - enteredDate.getMonth();
                      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < enteredDate.getDate())) {
                        age--;
                      }
                      setDOBErrorMessage(
                        e.target.value === ''
                          ? RequiredErrror
                          : age < 18
                            ? 'You must be at least 18 years old'
                            : age > 100
                              ? 'Age cannot be greater than 100'
                              : ''
                      );
                    }}
                    label="Date of Birth"
                    type="date"
                    fullWidth
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{dOBErrorMessage}</p>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setUserGender(newValue.id)
                        setGenderErrorMessage(newValue.id === '' ? RequiredErrror : '');
                      }}
                      options={metaData?.genders}
                      required
                      clearIcon={null}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Gender *" />}
                    />
                    <p style={{ color: 'red' }}>{genderErrorMessage}</p>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="regbuttonarea">
                <button type="button" onClick={onLogout} className="btn regbutton" style={{ fontFamily: 'DM Sans' }}>
                  Log out
                </button>
                <button type="button" onClick={navigateTosecondstep} className="btn regbutton" style={{ fontFamily: 'DM Sans' }}>
                  Submit
                </button>
              </div>
            </Card>
          </div >
          <Footer />
        </div>
      )}
    </>
  );
}