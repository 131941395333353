import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PolicyIcon from '@mui/icons-material/Policy';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import User from '../../Assests/imgs/user2.png';
import '../../CSS/SideBar.css';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { getProfilePhoto } from '../../Services/Profile/photoService.js';
import { MicrosoftLoginAgent } from '../../Services/Authentication/msal';
import { useMember } from "../../Contexts/memberContext.js";
import { useApp } from "../../Contexts/appContext";
import { Button, Dropdown, Space } from 'antd';
import SearchIcon from '@mui/icons-material/Search';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SideBar() {
  const theme = useTheme();
  const [setAccount] = useState(MicrosoftLoginAgent.getAccount());
  const [open, setOpen] = React.useState(false);
  const [openExpand, setOpenExpand] = React.useState(false);
  const { profile, setProfile, pendingReqCount, sentReqCount, topVisitorCount } = useMember();
  const navigate = useNavigate();
  const [mainProfilePhoto, setMainProfilePhoto] = useState([]);
  const { userType } = useApp();

  const items = [
    {
      key: '1',
      label: (
        <div onClick={() => navigate('/board')}>
          <h6 target="_blank" rel="noopener noreferrer">
            Dashboard
          </h6>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div onClick={() => navigate('/myprofile')}>
          <h6 target="_blank" rel="noopener noreferrer">
            My Profile
          </h6>
        </div>
      ),
    },
    userType === "ACTIVE_PROFILE_USER" ? {
      key: '3',
      label: (
        <div onClick={() => navigate('/search')}>
          <h6 target="_blank" rel="noopener noreferrer">
            Search
          </h6>
        </div>
      ),
    } : null,
    {
      key: '4',
      label: (
        <div onClick={() => onLogout()}>
          <h6 target="_blank" rel="noopener noreferrer">
            Sign Out
          </h6>
        </div>
      ),
    },
  ];


  const handleClick = () => {
    setOpenExpand(!openExpand); // Toggle the state when the menu icon is clicked
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onLogout = async () => {
    const successfulLogout = await MicrosoftLoginAgent.logout();

    if (successfulLogout) {
      const account = MicrosoftLoginAgent.getAccount();
      setAccount(account);
      window.location.replace(process.env.REACT_APP_REDIRECT_LOGOUT_URI);
    }
  };

  useEffect(() => {
    const getMainProfilePhoto = async () => {
      if (profile?.mainProfilePhotoId === 0) return;
      const photoResponse = await getProfilePhoto(profile?.mainProfilePhotoId);
      if (photoResponse.status === 200) {
        setMainProfilePhoto(photoResponse.data);
      } else if (photoResponse.status === 404) {
        setMainProfilePhoto([]);
      } else {
        console.log("Unable to load your profile photos");
      }
    };
    getMainProfilePhoto();
  }, [profile, profile?.mainProfilePhotoId]);

  return (
    <div>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} style={{ 'backgroundColor': '#ECE2F2', zIndex: '9' }}>
          <Toolbar className="appBar">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
              className="appbarWebMenuicon"
            >
              <MenuIcon />
            </IconButton>

            <div className="appbarcontent">
              <Typography variant="h6" noWrap component="div">
                {!open && (
                  <img
                    src="https://matrimonyprofilestorage.blob.core.windows.net/images/image%20(2).png"
                    alt="Vivaakam Logo"
                    style={{ width: '160px' }}
                  />
                )}
              </Typography>
            </div>
            <div className="appbarcontent">
              {/* <Space direction="vertical">
                <Space wrap>
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottom"
                  >
                    <IconButton className="appbarMenuicon">
                      <MenuIcon style={{color:'white'}} />
                    </IconButton>
                  </Dropdown>
                </Space>
              </Space> */}
              <Space direction="vertical">
                <Space wrap>
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottom"
                  >
                    <div className="profileimg2">
                      <img src={profile?.mainProfilePhotoId === 0 ? User : `data:image;base64,${mainProfilePhoto?.fileContents}`}
                        alt="profile picture" width="40" height="40" style={{ borderRadius: '100px', filter: mainProfilePhoto?.isApproved ? 'none' : (!mainProfilePhoto?.isApproved && profile?.mainProfilePhotoId !== 0) && 'blur(4px)' }}
                      />
                    </div>
                  </Dropdown>
                </Space>
              </Space>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer className="sideBar" variant="permanent" open={open} style={{ zIndex: '10' }}>
          <Toolbar style={{ backgroundColor: '#ECE2F2' }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
              style={{ color: 'rgb(123 80 167)' }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              <img
                src="https://matrimonyprofilestorage.blob.core.windows.net/images/image%20(2).png"
                alt="Vivaakam Logo"
                style={{ width: '130px' }}
              />
            </Typography>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose} style={{ color: 'rgb(123 80 167)' }}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
          </Toolbar>
          <Divider />
          <List>
            {open && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  className="sidebarbaisc"
                  src={profile?.mainProfilePhotoId === 0 ? User : `data:image;base64,${mainProfilePhoto?.fileContents}`}
                  alt="Vivaakam Logo"
                  style={{ width: '130px', filter: mainProfilePhoto?.isApproved ? 'none' : (!mainProfilePhoto?.isApproved && profile?.mainProfilePhotoId !== 0) && 'blur(4px)' }}
                />
              </div>
            )}
            {open && (
              <h2 className="sidebarbaisc dmsans proname" >{profile.firstName ? (profile.firstName.length > 14 ? `${profile.firstName.slice(0, 14)}...` : profile.firstName) : (profile.profileName.length > 14 ? `${profile.profileName.slice(0, 14)}...` : profile.profileName)}</h2>
            )}

            {open &&
              (<h6 className="sidebarbaisc dmsans">{profile?.location?.country?.name}</h6>
              )}
            {open &&
              (<div className="totoalProfileData" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* <div className="profiledata"><p5>{sentReqCount}</p5>
                <p>Pending requests</p></div>
              <div className="profiledata"><p5>{pendingReqCount}</p5>
                <p>Your impressions</p></div> */}
                <div className="profiledata" style={{ backgroundColor: '#F4EDFA', width: '80px', borderRadius: '5px', margin: '20px' }}>
                  <p5>{topVisitorCount}</p5>
                  <p className="dmsans">Profile views</p>
                </div>
              </div>
              )}

          </List>
          <List className="Listclass">
            <ListItemButton
              onClick={() => navigate('/board')}
              sx={{
                '&:hover': {
                  backgroundColor: '#F4EDFA', // Set hovering color to #E6EDFF
                  borderRadius: 30, // Set border radius to 30px
                  margin: '0px 10px 0px 10px',
                },
              }}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>

            <ListItemButton
              onClick={() => navigate('/myprofile')}
              sx={{
                '&:hover': {
                  backgroundColor: '#F4EDFA', // Set hovering color to #E6EDFF
                  borderRadius: 30, // Set border radius to 30px
                  margin: '0px 10px 0px 10px',
                },
              }}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItemButton>
            {userType === "ACTIVE_PROFILE_USER" &&
              <ListItemButton
                onClick={() => navigate('/search')}
                sx={{
                  '&:hover': {
                    backgroundColor: '#F4EDFA', // Set hovering color to #E6EDFF
                    borderRadius: 30, // Set border radius to 30px
                    margin: '0px 10px 0px 10px',
                  },
                }}
              >
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Search" />
              </ListItemButton>
            }
          </List>
          <div style={{ position: 'absolute', bottom: '0', left: '0', right: '0' }}>
            <hr style={{ width: '100%', textAlign: 'left', marginLeft: '10px', marginRight: '10px' }} />
            <List className="Listclass">
              <ListItemButton
                onClick={() => onLogout()}
                sx={{
                  '&:hover': {
                    backgroundColor: '#F4EDFA', // Set hovering color to #E6EDFF
                    borderRadius: 30, // Set border radius to 30px
                    margin: '0px 10px 0px 10px',
                  },
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItemButton>
            </List>
          </div>
        </Drawer>
        {/* <Footer/> */}
        <AppBar position="absolute" open={open} style={{ 'backgroundColor': 'black', zIndex: '9', marginBottom: '0px' }} />
      </Box>
    </div>
  );
}