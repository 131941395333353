import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import "../../CSS/Contact.css";
import data from "./faqData";
import Header from "../NavBar/Header";
import Footer from "../FooterPages/Footer";

const FAQSet = ({ question, answer }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "rgb(123 80 167)" }} />}
        aria-controls="faq-content"
        id="faq-header"
      >
        <Typography variant="subtitle1" style={{ fontFamily: "dm sans", color: '#656565' }}>
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="subtitle2" style={{ color: "#000000" }}>
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const FAQs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />
      <div className="bottomspacedash">
        <div className="cover-image-container">
          {/* <div className="overlay"></div> */}
          <img
            className="cover-image"
            src="https://img.freepik.com/premium-vector/info-bubble-sign-icon-vector-set-red-green-blue-orange-purple-help-information-modern-graphic-design-reference-hint-tip-important-data-sign-button-attention-alert-message-notice-remark-image_101884-2651.jpg?w=1380"
            alt="Cover"
          />
          <div className="cover-text">
            <h1 style={{ fontFamily: "dm sans" }}>FAQs</h1>
          </div>
        </div>
        <div className="container contact-container">
          <h4>FAQs</h4>
          <div className="row mt-2 py-2">
            {data.rows.map(faqDetails => (
              <FAQSet
                key={faqDetails.id}
                question={faqDetails.title}
                answer={faqDetails.content}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQs;