import React from 'react';
import logoImage from '../../Assests/imgs/vivakamLogo.png';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import { useApp } from '../../Contexts/appContext';

const Header = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { userType } = useApp();

  const items = [
    {
      key: '1',
      label: (
        <div onClick={() => navigate(isAuthenticated ? '/board' : '/')}>
          <h6 target="_blank" rel="noopener noreferrer">
            Home
          </h6>
        </div>
      ),
    },

  ];

  return (
    <div style={{ backgroundColor: '#ECE2F2', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 20px' }}>
      <img src="https://matrimonyprofilestorage.blob.core.windows.net/images/image%20(2).png" alt="Logo" style={{ height: '30px', width: 'auto', cursor: 'pointer' }} onClick={() => navigate(isAuthenticated ? '/board' : '/')} />
      <div className="appbarcontent" style={{ display: 'flex', justifyContent: 'end' }}>
        {(userType === "INACTIVE_PROFILE_USER" || userType === "ACTIVE_PROFILE_USER" || userType === null) &&
          <Space direction="vertical">
            <Space wrap>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
              >
                <IconButton className="">
                  <MenuIcon style={{ color: 'rgb(123 80 167)' }} />
                </IconButton>
              </Dropdown>
            </Space>
          </Space>
        }
      </div>
    </div>
  );
};

export default Header;
