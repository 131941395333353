import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import '../../CSS/Footer.css'

const AppBar = () => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        p: 1,
        display: 'flex',
        justifyContent: 'center',
        mb: 1,
        backgroundColor: '#ECE2F2',
      }}
    >
      <div>
        <img
          src="https://matrimonyprofilestorage.blob.core.windows.net/images/image%20(2).png"
          alt="Vivaakam Logo"
          style={{ width: '130px' }}
        />
      </div>
    </Box>
  );
};

export default AppBar;