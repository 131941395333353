import { Grid, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import TermsAndConditions from './termsAndConditions';
import Header from "../NavBar/Header";
import Footer from "../FooterPages/Footer";
import React, { useEffect } from "react";


const DetailsWrap = styled(Box)(({ theme }) => ({
	padding: '0 30px',

	'& .label': {
		padding: 0,
		[theme.breakpoints.down('md')]: {
			overflowY: 'scroll',
		},
	},

	[theme.breakpoints.down('md')]: {
		padding: '0 10px',
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: 22,
	fontWeight: 700,
	paddingBottom: '13px',
	marginBottom: '10px',
	borderBottom: '2px solid #e3e3e3',
	textAlign: 'left',
}));

const Terms = () => {

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<Header />
			<div className="dashboard topspacedash">
				<Grid container spacing={4}>
					<Grid item xs={12} style={{ paddingTop: '8px' }}>
						<Typography
							sx={{ textAlign: 'center', fontSize: 28, fontWeight: 700, }}
							variant='h4'
						>
							Terms & Conditions
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<DetailsWrap>
						<h6>Read Carefully</h6>
						<hr/>
							<div align="justify">
								<TermsAndConditions />
							</div>
						</DetailsWrap>
					</Grid>
				</Grid>
			</div>
			<Footer />
		</>
	);
}


export default Terms