import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { createProfile, getProfile } from "../../Services/Profile/profileService";
import { MicrosoftLoginAgent } from "../../Services/Authentication/msal";
import {
  Card,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import Autocomplete from '@mui/material/Autocomplete';
import { useMember } from "../../Contexts/memberContext";
import { useApp } from "../../Contexts/appContext";
import { Spinner, ButtonSpinner } from "../Common/Spinner";
import Footer from "../FooterPages/Footer.js";
import AppBar from "../NavBar/AppBar.js";
import '../../CSS/Registration.css';
import { getAge } from "../../Utils/getAge.js";

const formStyle = {
  fontFamily: 'DM Sans',
};

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 30,
    padding: theme.spacing(2),
    maxWidth: 400,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ProfileCreation() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [setAccount] = useState(MicrosoftLoginAgent.getAccount());
  const [hasChildren, setHasChildren] = useState(false);
  const { profile, setProfile } = useMember();
  const { metaData, setUserType } = useApp();
  const [userMaritalStatus, setUserMatitalStatus] = useState('');
  const [userHeight, setUserHeight] = useState('');
  const [userProfileName, setUserProfileName] = useState(profile?.profileName || '');
  const [userInitials, setUserInitials] = useState(profile?.surname || '');
  const [userAboutMe, setUserAboutMe] = useState('');
  const [dietHabbit, setUserDietHabbit] = useState(null);
  const [smokingHabbit, setUserSmokingHabbit] = useState(null);
  const [drinkingHabbit, setUserDrinkingHabbit] = useState(null);
  const [fatherJobStatus, setFatherJobStatus] = useState(null);
  const [motherJobStatus, setMotherJobStatus] = useState(null);
  const [childrenCount, setChildrenCount] = useState(0);
  const [numberOfBrothers, setNumberOfBrothers] = useState(0);
  const [numberOfMarriedBrothers, setNumberOfMarriedBrothers] = useState(0);
  const [numberOfSisters, setNumberOfSisters] = useState(0);
  const [numberOfMarriedSisters, setNumberOfMarriedSisters] = useState(0);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [logoutBtnLoading, setlogoutBtnLoading] = useState(false);

  const [profileNameErrorMessage, setProfileNameErrorMessage] = useState('');
  const [initialsErrorMessage, setInitialsErrorMessage] = useState('');
  const [heightErrorMessage, setHeightErrorMessage] = useState('');
  const [maritalStatusErrorMessage, setMaritalStatusErrorMessage] = useState('');
  const [childrenCountErrorMessage, setChildrenCountErrorMessage] = useState('');
  const [marriedBrothersErrorMessage, setMarriedBrothersErrorMessage] = useState('');
  const [marriedSistersErrorMessage, setMarriedSistersErrorMessage] = useState('');
  const [aboutMeErrorMessage, setAboutMeErrorMessage] = useState('');
  const [limitMessage, setLimitMessage] = useState('');
  const [brotherCountErrMessage, setBrotherCountErrorMessage] = useState('');
  const [sisterCountErrMessage, setSisterCountErrorMessage] = useState('');

  const characterRegExp = /^[a-zA-Z0-9]+$/;
  const initialsRegExp = /^[a-zA-Z]*$/;
  const RequiredErrror = 'This field is required';
  const regularExpression_badWords = /\b(crap|ugly|brat|fool|fuck(?:ing|er)?|f\*ck(?:ing|er)?|bitch|b\*tch|shit|sh\*t|dumb|couch potato|arse(?:hole)?|asshole|(\*ssh\*l\*|\*\*\*\*|c\*ck|c\*cks\*ck\*r|c\*nt|dickhead|d\*c\*h\*a\*|f\*c\*|f\*ckw\*t|fuk(?:ing)?|f\*k(?:ing)?|mother\*\*\*\*er|m\*th\*rf\*ck\*r|\*\*\*\*\*\*|n\*gg\*r|pussy|p\*ssy|wanker|w\*nk\*r|whore|wh\*r\*|slag)\b)/gi;

  useEffect(() => {
    const fetchData = async () => {
      if (!profile) {
        const { status: profileStatus, data: profile } = await getProfile("me");
        if (profileStatus === 200) setProfile(profile);
      }
      setUserProfileName(profile?.profileName);
      setUserInitials(profile?.surname);
    };
    fetchData();
  }, [profile, metaData]);

  const onLogout = async () => {
    setlogoutBtnLoading(true);
    const successfulLogout = await MicrosoftLoginAgent.logout();
    if (successfulLogout) {
      const account = MicrosoftLoginAgent.getAccount();
      setAccount(account);
      window.location.replace(process.env.REACT_APP_REDIRECT_LOGOUT_URI);
      setlogoutBtnLoading(false);
    }
  };

  const navigateToDashboard = async () => {
    //validations
    setProfileNameErrorMessage(!userProfileName ? RequiredErrror : !characterRegExp.test(userProfileName) ? 'Only numbers and alphabetic characters allowed' : '');
    setInitialsErrorMessage(!initialsRegExp.test(userInitials) ? 'Only alphabetic characters allowed' : '');
    setHeightErrorMessage(userHeight === '' ? RequiredErrror : '');
    setMaritalStatusErrorMessage(userMaritalStatus === '' ? RequiredErrror : '');
    setChildrenCountErrorMessage(hasChildren && (childrenCount <= 0 || isNaN(childrenCount)) ? 'Children count must be greater than 0' : childrenCount > 10 ? 'You can add a maximum of 10 children' : '');
    setBrotherCountErrorMessage(numberOfBrothers > 10 ? 'You can add maximum 10 brothers' : numberOfBrothers < 0 ? 'Brothers count must be greater than 0' : '');
    setSisterCountErrorMessage(numberOfSisters > 10 ? 'You can add maximum 10 sisters' : numberOfSisters < 0 ? 'Sisters count must be greater than 0' : '');
    setMarriedSistersErrorMessage(numberOfSisters !== null && numberOfSisters > 0 && numberOfMarriedSisters > numberOfSisters ? 'Married sisters should not exceed total sisters count' :
      (numberOfMarriedSisters < 0 || numberOfMarriedSisters > 10) ? 'Please select the number of sisters properly (0-10)' : '');
    setMarriedBrothersErrorMessage(numberOfBrothers !== null && numberOfBrothers > 0 && numberOfMarriedBrothers > numberOfBrothers ? 'Married brothers should not exceed total brothers count' :
      (numberOfMarriedBrothers < 0 || numberOfMarriedBrothers > 10) ? 'Please select the number of brothers properly (0-10)' : '');
    if (userAboutMe.length > 400) {
      setAboutMeErrorMessage('Maximum character limit exceeded (400 characters)');
      return;
    }
    if (userAboutMe.trim() === '' || !regularExpression_badWords.test(userAboutMe)) {
      if (regularExpression_badWords.test(userAboutMe)) {      //handled an error happens if user click submit btn more than one time
        return;
      }
      setAboutMeErrorMessage('');
    } else {
      setAboutMeErrorMessage('Please refrain from using offensive words');
      return;
    }

    if (!userProfileName || userHeight === '' || !characterRegExp.test(userProfileName) || !initialsRegExp.test(userInitials)
      || userMaritalStatus === '' ||
      (numberOfBrothers > 10 || numberOfBrothers < 0) || (numberOfSisters > 10 || numberOfSisters < 0) || (hasChildren && (childrenCount <= 0 || isNaN(childrenCount))) || childrenCount > 10
      || (numberOfSisters !== null && numberOfSisters > 0 && numberOfMarriedSisters > numberOfSisters) || (numberOfMarriedSisters < 0 || numberOfMarriedSisters > 10)
      || (numberOfBrothers !== null && numberOfBrothers > 0 && numberOfMarriedBrothers > numberOfBrothers) || (numberOfMarriedBrothers < 0 || numberOfMarriedBrothers > 10)
    ) {
      return;
    }
    //get metaData IDs for set default partner criteria values
    const age = getAge(profile?.dob);
    let lowerBoundAgeName, upperBoundAgeName, lowerBoundAgeId, upperBoundAgeId, lowerBoundHeightId, upperBoundHeightId, maritalStatuses, hasChildrenID;
    if (profile?.gender.name === 'Female') {
      lowerBoundAgeName = age;
      upperBoundAgeName = Math.min(100, age + 5);
    } else if (profile?.gender.name === 'Male') {
      lowerBoundAgeName = Math.max(18, age - 5);
      upperBoundAgeName = age;
    }
    metaData.ages.forEach(item => {
      if (item.name === lowerBoundAgeName.toString()) {
        lowerBoundAgeId = item.id;
      } else if (item.name === upperBoundAgeName.toString()) {
        upperBoundAgeId = item.id;
      }
    });
    metaData.heights.forEach(item => {
      if (item.name === "Below 4'0\"") {
        lowerBoundHeightId = item.id;
      } else if (item.name === "Above 8'0\"") {
        upperBoundHeightId = item.id;
      }
    });
    metaData.maritalStatuses.forEach(item => {
      if (item.name === "Single") {
        maritalStatuses = item;
      }
    });
    metaData.partnerChildrenCriteria.forEach(item => {
      if (item.name === "No") {
        hasChildrenID = item.id;
      }
    });
    //user inputs object
    const data = {
      profileName: userProfileName,
      surname: userInitials,
      heightId: userHeight,
      maritalStatusId: userMaritalStatus,
      hasChildren: hasChildren,
      numberOfKids: childrenCount,
      dietHabbitId: dietHabbit,
      dob: profile?.dob || null,
      drinkingHabbitId: drinkingHabbit,
      smokingHabbitId: smokingHabbit,
      ageUpperBoundId: upperBoundAgeId || null,
      ageLowerBoundId: lowerBoundAgeId || null,
      heightUpperBoundId: upperBoundHeightId || null,
      heightLowerBoundId: lowerBoundHeightId || null,
      hasChildrenId: hasChildrenID || null,
      maritalStatuses: [maritalStatuses],
      family: {
        fatherStatusId: fatherJobStatus,
        motherStatusId: motherJobStatus,
        numberOfBrothers: numberOfBrothers,
        numberOfBrothersMarried: numberOfMarriedBrothers,
        numberOfSisters: numberOfSisters,
        numberOfSistersMarried: numberOfMarriedSisters
      },
      about: userAboutMe === '' ? null : userAboutMe
    };
    try {
      setAddBtnLoading(true);
      const response = await createProfile(data, profile?.id);
      if (response.status === 201) {
        setUserType("INACTIVE_PROFILE_USER");
        setProfile(response.data);
        swal({
          text: "Profile created",
          icon: 'success',
          buttons: {
            cancel: 'Close',
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        }).then(async () => {
          navigate("/board");
        });
      } else {
        console.log("error", response.data.errors);
        swal({
          text: 'Profile creation unsuccessful',
          icon: 'error',
          dangerMode: true,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      swal({
        text: 'Profile creation unsuccessful',
        icon: 'error',
        dangerMode: true,
      });
    }
    setAddBtnLoading(false);
  };

  return (
    <>
      {!metaData || !profile ? (<Spinner></Spinner>) : (
        <div >
          <AppBar />
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', minHeight: '100vh' }}>
            <Card className="cardContent" style={{ padding: '20px', marginBottom: '50px', borderRadius: '15px' }}>
              <Typography className="topic" variant="h6" gutterBottom style={{ fontFamily: 'DM Sans' }}>
                Profile Creation
              </Typography>
              <Grid container spacing={2} style={formStyle}>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    label="Profile Name"
                    value={userProfileName}
                    fullWidth
                    required
                    onChange={(e) => {
                      setUserProfileName(e.target.value);
                      setProfileNameErrorMessage(!e.target.value ? RequiredErrror : !characterRegExp.test(e.target.value) ? 'Only numbers and alphabetic characters allowed' : '');
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{profileNameErrorMessage}</p>
                  <TextField
                    label="First Name"
                    fullWidth
                    value={profile?.firstName || ''}
                    disabled
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <TextField
                    label="Initials"
                    fullWidth
                    value={userInitials}
                    inputProps={{ maxLength: 1 }}
                    onChange={(e) => {
                      setUserInitials(e.target.value);
                      setInitialsErrorMessage(!initialsRegExp.test(e.target.value) ? 'Only alphabetic characters allowed' : '');
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{initialsErrorMessage}</p>
                  <TextField
                    label="Date of birth"
                    fullWidth
                    value={profile?.dob.split("T")[0] || ''}
                    disabled
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <TextField
                    label="Gender"
                    fullWidth
                    value={profile?.gender.name || ''}
                    disabled
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setUserHeight(newValue.id); //newValue.id gives you the id of the selected option
                        setHeightErrorMessage(newValue.id === '' ? RequiredErrror : '');
                      }}
                      options={metaData.heights}
                      required
                      clearIcon={null}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Height *"
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />}
                    />
                    <p style={{ color: 'red' }}>{heightErrorMessage}</p>
                  </FormControl>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        setUserMatitalStatus(newValue.id);
                        setMaritalStatusErrorMessage(newValue.id === '' ? RequiredErrror : '');
                      }}
                      options={metaData.maritalStatuses}
                      clearIcon={null}
                      required
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Marital Status *"
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />}
                    />
                    <p style={{ color: 'red' }}>{maritalStatusErrorMessage}</p>
                  </FormControl>
                  <FormControlLabel
                    control={<Checkbox checked={hasChildren} onChange={() => setHasChildren(!hasChildren)} />}
                    label="Has Children"
                    style={{ marginTop: '16px', marginBottom: '8px' }}
                  />
                  {hasChildren && (
                    <>
                      <TextField
                        label="Number of Children"
                        type="number"
                        fullWidth
                        onChange={(e) => {
                          const childrenCount = parseInt(e.target.value);
                          setChildrenCount(childrenCount);
                          setChildrenCountErrorMessage(hasChildren && (parseInt(e.target.value) <= 0 || isNaN(childrenCount)) ? 'Children count must be greater than 0' : parseInt(e.target.value) > 10 ? 'You can add a maximum of 10 children' : '');
                        }}
                        inputProps={{ min: 1, max: 10 }}
                        className={classes.textField}
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />
                      <p style={{ color: 'red' }}>{childrenCountErrorMessage}</p>
                    </>
                  )}
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <FormControl fullWidth className={classes.textField} >
                    <Autocomplete
                      onChange={(event, newValue) => setFatherJobStatus(newValue !== null ? newValue.id : null)}
                      options={metaData.jobStatuses}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Father"
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }} />}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => setMotherJobStatus(newValue !== null ? newValue.id : null)}
                      options={metaData.jobStatuses}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Mother"
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => setUserDietHabbit(newValue !== null ? newValue.id : null)}
                      options={metaData.dietHabbits}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Dietary Habit"
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => setUserSmokingHabbit(newValue !== null ? newValue.id : null)}
                      options={metaData.smokingHabbits}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Smoking Habit"
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.textField}>
                    <Autocomplete
                      onChange={(event, newValue) => setUserDrinkingHabbit(newValue !== null ? newValue.id : null)}
                      options={metaData.drinkingHabbits}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Drinking Habit"
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />}
                    />
                  </FormControl>
                  <TextField
                    label="Number of Brothers"
                    type="number"
                    fullWidth
                    inputProps={{ min: 0, max: 10 }}
                    value={numberOfBrothers}
                    onChange={(e) => {
                      setNumberOfBrothers(parseInt(e.target.value));
                      let value = parseInt(e.target.value);
                      value === 0 ? setNumberOfMarriedBrothers(parseInt(e.target.value)) : setNumberOfMarriedBrothers(numberOfMarriedBrothers);
                      setBrotherCountErrorMessage(e.target.value > 10 ? 'You can add maximum 10 brothers' : e.target.value < 0 ? 'Brothers count must be greater than 0' : '');
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{brotherCountErrMessage}</p>
                  {numberOfBrothers !== null && numberOfBrothers > 0 && (
                    <>
                      <TextField
                        label="Number of Married Brothers"
                        type="number"
                        fullWidth
                        inputProps={{ min: 0, max: numberOfBrothers }}
                        value={numberOfMarriedBrothers}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (!isNaN(value) && value <= numberOfBrothers) {
                            setNumberOfMarriedBrothers(value);
                            setMarriedBrothersErrorMessage(numberOfBrothers !== null && numberOfBrothers > 0 && value > numberOfBrothers ? 'Married brothers should not exceed total brothers count' :
                              (value < 0 || value > 10) ? 'Please select the number of brothers properly (0-10)' : '');
                          }
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />
                      <p style={{ color: 'red' }}>{marriedBrothersErrorMessage}</p>
                    </>
                  )}
                  <TextField
                    label="Number of Sisters"
                    type="number"
                    fullWidth
                    inputProps={{ min: 0, max: 10 }}
                    value={numberOfSisters}
                    onChange={(e) => {
                      setNumberOfSisters(parseInt(e.target.value));
                      let value = parseInt(e.target.value);
                      value === 0 ? setNumberOfMarriedSisters(value) : setNumberOfMarriedSisters(numberOfMarriedSisters);
                      setSisterCountErrorMessage(e.target.value > 10 ? 'You can add maximum 10 sisters' : e.target.value < 0 ? 'Sisters count must be greater than 0' : '');
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'red' }}>{sisterCountErrMessage}</p>
                  {numberOfSisters !== null && numberOfSisters > 0 && (
                    <>
                      <TextField
                        label="Number of Married Sisters"
                        type="number"
                        fullWidth
                        inputProps={{ min: 0, max: numberOfSisters }}
                        value={numberOfMarriedSisters}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (!isNaN(value) && value <= numberOfSisters) {
                            setNumberOfMarriedSisters(value);
                            setMarriedSistersErrorMessage(numberOfSisters !== null && numberOfSisters > 0 && value > numberOfSisters ? 'Married sisters should not exceed total sisters count' :
                              (value < 0 || value > 10) ? 'Please select the number of sisters properly (0-10)' : '');
                          }
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                          style: { fontFamily: 'DM Sans' },
                        }}
                      />
                      <p style={{ color: 'red' }}>{marriedSistersErrorMessage}</p>
                    </>
                  )}
                  <TextField
                    id="filled-multiline-flexible"
                    label="About me"
                    multiline
                    fullWidth
                    inputProps={{ maxLength: 400 }}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      setUserAboutMe(inputText);
                      if (inputText.length > 399) {
                        setLimitMessage("Max 400 characters");
                      } else {
                        setLimitMessage('');
                      }
                      setAboutMeErrorMessage(inputText.trim() === '' || !regularExpression_badWords.test(inputText)
                        ? '' : 'Please refrain from using offensive words'
                      );
                    }}
                    variant="filled"
                    InputLabelProps={{
                      style: { fontFamily: 'DM Sans' },
                    }}
                  />
                  <p style={{ color: 'black' }}>{limitMessage}</p>
                  <p style={{ color: 'red' }}>{aboutMeErrorMessage}</p>
                </Grid>
              </Grid>
              <div className="regbuttonarea">
                <button type="button" onClick={onLogout} className="btn regbutton" style={{ fontFamily: 'DM Sans' }}>
                  {logoutBtnLoading ? <ButtonSpinner /> : 'Log out'}
                </button>
                <button onClick={navigateToDashboard} type="button" className="btn regbutton" disabled={addBtnLoading ? true : false} style={{ fontFamily: 'DM Sans' }}>
                  {addBtnLoading ? <ButtonSpinner /> : 'Add profile details'}
                </button>
              </div>
            </Card>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}