import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import '../../CSS/Footer.css'
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import vivakamLogo from '../../Assests/imgs/vivakamLogo.png';
import EmailIcon from '@mui/icons-material/Email';
import phone from '../../Assests/imgs/phone.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useIsAuthenticated } from "@azure/msal-react";
import { useApp } from '../../Contexts/appContext';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import Nav from "react-bootstrap/Nav"

const Footer = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { userType } = useApp();

  return (
    <div style={{ backgroundColor: '#ECE2F2', paddingLeft: '16px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: { md: "none", lg: "none", sm: "block", xs: "block" } }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img
              src="https://matrimonyprofilestorage.blob.core.windows.net/images/image%20(2).png"
              alt="Vivaakam Logo"
              style={{ width: '130px' }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div className="">
            <div
            // onClick={() => navigate('/contactus')}
            >
              <h5 className="pointer">Contact Us</h5>
            </div>
            <div className="iconbutton">
              <EmailIcon style={{ width: '15px' }} />
              <a href="mailto:support@vivaakam.com" className="detailsfot" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="subtitle2">support@vivaakam.com</Typography>
              </a>
            </div>
            <div className="iconbuttonphone">
              <img src={phone} style={{ width: '20px' }} />
              <a href="tel:+94701703996" className="detailsfot" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="subtitle2">+94 70 170 3996</Typography>
              </a>
            </div>
            <div className="iconbutton">
              <LocationOnIcon style={{ width: '15px' }} />
              <a href="https://www.google.com/maps/search/?api=1&query=No.+28+1-1,+Temple+Lane,+Colombo+03,+Sri+Lanka" target="_blank" rel="noopener noreferrer" className="detailsfot" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="subtitle2">No. 28 1-1, Temple Lane, Colombo 03, Sri Lanka</Typography>
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={0} sm={0} md={4} lg={4} sx={{ display: { md: "block", lg: "block", sm: "none", xs: "none" } }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img
              src="https://matrimonyprofilestorage.blob.core.windows.net/images/image%20(2).png"
              alt="Vivaakam Logo"
              style={{ width: '200px' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div className="footerWebAlignemnts">
            <div className="lines2">
              {(userType === "INACTIVE_PROFILE_USER" || userType === "ACTIVE_PROFILE_USER" || userType === null) &&
                <div className="iconbutton">
                  <Link color="inherit" onClick={() => navigate(isAuthenticated ? '/board' : '/')} className="no-underline" >
                    <Typography className="pointer no-underline " variant="subtitle2">Home</Typography>
                  </Link>
                </div>
              }
              <div className="iconbutton">
                <Link className="no-underline" color="inherit" onClick={() => navigate('/faq')}>
                  <Typography className="pointer no-underline" variant="subtitle2">FAQs</Typography>
                </Link>
              </div>
              <div className="iconbutton">
                <Link className="no-underline" color="inherit" onClick={() => navigate('/terms')}>
                  <Typography className="pointer no-underline" variant="subtitle2">T & C</Typography>
                </Link>
              </div>
              <div className="iconbutton">
                <Link className="no-underline" color="inherit" onClick={() => navigate('/policy')}>
                  <Typography className="pointer" variant="subtitle2">Privacy Policy</Typography>
                </Link>
              </div>
              <div className="social-icons">
                <a className='socialIcon' href="https://www.linkedin.com/company/vivaakam/"><LinkedInIcon /></a>
                <a href="https://www.youtube.com/channel/UCuN7MutXznSWVURcMshU7PA"><YouTubeIcon /></a>
                <a href="https://www.facebook.com/profile.php?id=100092653369059"><FacebookIcon /></a>
                <a href="https://www.instagram.com/vivaakam/"><InstagramIcon /></a>
                <a href="https://www.tiktok.com/@vivaakam?lang=en"><i class="bi bi-tiktok"></i></a>
                <a href="https://twitter.com/vivaakam"><XIcon /></a>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="powered">
            <div>
              All Rights Reserved &copy; 2025 Vivaakam. Designed & developed by{' '}&nbsp;
            </div>
            <div>
              <Link color="inherit" href="https://www.infinityinnovators.com/">
                Infinity Innovators
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;