// import React, { useRef, useState, useEffect } from "react";
// import Grid from '@mui/material/Grid';
// import '../../CSS/Homepage.css';
// import Box from '@mui/material/Box';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import coupleVideo from '../../Assests/imgs/coupleVideo.mp4';
// import vivakamLogo from '../../Assests/imgs/ViVlogo.png';
// import SingleSignOn from './SingleSignOn.js';
// import { Carousel } from 'antd';
// import { IconButton, Typography } from '@mui/material';
// import EmailIcon from '@mui/icons-material/Email';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import Link from '@mui/material/Link';
// import logoImage from '../../Assests/imgs/ViVlogo.png';
// import { useNavigate } from 'react-router-dom';
// import phone from '../../Assests/imgs/phone.png';
// import couplePhoto from '../../Assests/imgs/couplePhoto.jpg';
// import couplePhoto2 from '../../Assests/imgs/couplephoto2.webp';
// import XIcon from '@mui/icons-material/X';

// const Header = () => {

//   // const [isModalOpen, setIsModalOpen] = useState(false);

//   // const handleModalOpen = () => {
//   //   setIsModalOpen(true);
//   // };

//   // const handleModalClose = () => {
//   //   setIsModalOpen(false);
//   // };

//   // const handleSubmit = (event) => {
//   //   event.preventDefault();
//   //   const data = new FormData(event.currentTarget);
//   //   // Add any other logic you need here
//   // };
//   const handleModalOpen = () => {
//     setIsModalOpen(true);
//   };

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleModalClose = () => {
//     setIsModalOpen(false);
//   };

//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//     setIsModalOpen(false);
//   }, []);

//   const navigate = useNavigate();

//   return (
//     <div style={{ backgroundColor: '#571B38', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 20px' }}>
//       <img onClick={() => navigate('/')} src={logoImage} alt="Logo" style={{ height: '36px', width: 'auto' }} />
//       {!isModalOpen && <p5 onClick={handleModalOpen} className="signIn">SignIn / SignUp</p5>}
//       {isModalOpen && <SingleSignOn handleClose={handleModalClose} />}
//     </div>
//   );
// };

// const CoverImage = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: "smooth" });

//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 600);
//     };

//     window.addEventListener('resize', handleResize);

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const handleModalOpen = () => {
//     setIsModalOpen(true);
//   };

//   const handleModalClose = () => {
//     setIsModalOpen(false);
//   };

//   const contentStyle = {
//     height: '90vh',
//     color: '#fff',
//     lineHeight: '160px',
//     textAlign: 'center',
//     background: '#364d79',
//     justifyContent: 'center',
//     backgroundPosition: 'center',
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'cover',
//   };

//   const slides = [
//     {
//       desktopBackground: 'url("https://images.unsplash.com/photo-1626025378194-b03e38bc5585?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
//       mobileBackground: 'url("https://images.pexels.com/photos/5747219/pexels-photo-5747219.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
//       text1: 'Find your partner',
//       text2: 'Special people around you',
//       text3: 'Join with Vivaakam',
//     },
//     {
//       desktopBackground: 'url("https://images.unsplash.com/photo-1640953146604-2596432ee1eb?q=80&w=1872&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
//       mobileBackground: 'url("https://images.pexels.com/photos/12992077/pexels-photo-12992077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
//       text1: 'தமிழ் மக்களிற்கான',
//       text2: 'தனித்துவமான திருமண சேவை',
//       text3: 'உங்களை வரவேற்கின்றது',
//     },
//     {
//       desktopBackground: 'url("https://images.pexels.com/photos/17657697/pexels-photo-17657697/free-photo-of-young-couple-in-traditional-wedding-clothing.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
//       mobileBackground: 'url("https://images.pexels.com/photos/1024996/pexels-photo-1024996.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
//       text1: 'Welcome!',
//       text2: 'Find your Special Someone',
//       text3: 'Your story is waiting to happen!',
//     },
//     {
//       desktopBackground: 'url("https://images.unsplash.com/photo-1640953146098-944d790b5cc4?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
//       mobileBackground: 'url("https://images.pexels.com/photos/3014860/pexels-photo-3014860.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
//       text1: 'வணக்கம்',
//       text2: 'உங்கள்  வாழ்க்கை துணையை',
//       text3: 'தேர்ந்தெடுங்கள்',
//     },
//   ];

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column' }}>
//       <Carousel autoplay style={{ width: '100%' }}>
//         {slides.map((slide, index) => (
//           <div key={index}>
//             <div style={{ ...contentStyle, position: 'relative' }}>
//               <div style={{ backgroundImage: isMobile ? slide.mobileBackground : slide.desktopBackground, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '90vh' }}></div>
//               <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//                 <h3 className="landtext landtext1">{slide.text1}</h3>
//                 <h1 className="landtext landtext2">{slide.text2}</h1>
//                 <h3 className="landtext landtext3">{slide.text3}</h3>
//                 <div style={{ display: 'flex', justifyContent: 'center' }}>
//                   <h6 onClick={handleModalOpen} style={{ background: 'linear-gradient(to bottom, #FFA734, #FF7D40)', cursor: 'pointer', width: 'fit-content', padding: '10px', borderRadius: '30px' }}>Get Started</h6>
//                 </div>
//               </div>
//               {isModalOpen && <SingleSignOn handleClose={handleModalClose} />}
//             </div>
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// const BasicInfo = () => {

//   const [isPlaying, setIsPlaying] = useState(false);
//   const videoRef = useRef(null);

//   const handlePlayClick = () => {
//     if (videoRef.current) {
//       if (videoRef.current.paused) {
//         videoRef.current.play();
//       } else {
//         videoRef.current.pause();
//       }
//     }
//   };

//   const handleVideoPlay = () => {
//     setIsPlaying(true);
//   };

//   const handleVideoPause = () => {
//     setIsPlaying(false);
//   };

//   return (
//     <div style={{ backgroundColor: '#ece2f2' }}>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={12} md={12} lg={12}>
//           <h1 className="subtopic1">
//             About Us
//           </h1>
//         </Grid>

//         <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '20px', justifyContent: 'center' }}>
//           <Grid item xs={12} sm={12} md={6} lg={6}>
//             <div className="p6">
//               <p6>
//                 We are dedicated to facilitating individuals from the Tamil community worldwide in their search for the perfect life partner.
//                 Our platform aims to minimize third-party intervention in matchmaking by providing a direct connection between potential partners.
//                 We appreciate your interest in our services and encourage you to explore our site to find your ideal match.
//               </p6>
//             </div>
//           </Grid>
//           <Grid item lg={5} md={5} sm={0} xs={0} sx={{ display: { md: "block", lg: "block", sm: "none", xs: "none" } }}>
//             <div className="about_right">
//               <img
//                 className="couplephoto"
//                 src={couplePhoto}
//                 style={{
//                   width: '400px',
//                   boxShadow: '16px -16px 8px rgba(0, 0, 0, 0.5)' // 4px x-offset, 4px y-offset, 8px blur radius, rgba for transparency
//                 }}
//               />
//             </div>
//           </Grid>
//         </div>

//         <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '20px', justifyContent: 'center' }}>
//           <Grid item lg={5} md={5} sm={0} xs={0} sx={{ display: { md: "block", lg: "block", sm: "none", xs: "none" } }}>
//             <div className="about_right">
//               <img
//                 className="couplephoto"
//                 src={couplePhoto2}
//                 style={{
//                   width: '400px',
//                   boxShadow: '-16px 16px 8px rgba(0, 0, 0, 0.5)' // 4px x-offset, 4px y-offset, 8px blur radius, rgba for transparency
//                 }}
//               />
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={12} md={6} lg={6}>
//             <div className="p7">
//               <p6>
//                 உலகெங்கிலும் பரந்து வாழும் இலங்கைத் தமிழர்களின் இணை தேடலில் இடையீடுகளை இயன்றவரைக்
//                 குறைத்து இரு தரப்புக்குமான விரைவான நேரடித் தொடர்பாடலினூடாக பொருத்தமானவர்களை கண்டறியவேண்டும்
//                 என்ற நோக்கிலானது எம் திருமண சேவை.
//               </p6>
//             </div>
//           </Grid>
//         </div>
//       </Grid>

//       <div className="imgList">
//         <div style={{ display: 'flex', flexDirection: 'row' }}>
//           <img className="imgesoflist" src="https://images.pexels.com/photos/11749509/pexels-photo-11749509.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
//           <img className="imgesoflist" src="https://images.pexels.com/photos/7176689/pexels-photo-7176689.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
//           <img className="imgesoflist" src="https://images.pexels.com/photos/11749507/pexels-photo-11749507.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
//         </div>
//       </div>
//     </div>

//   );
// };

// const Footer = () => {

//   const navigate = useNavigate();

//   return (
//     <div style={{ backgroundColor: '#571B38', color: 'white' }}>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: { md: "none", lg: "none", sm: "block", xs: "block" } }}>
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
//             <img
//               src={vivakamLogo}
//               alt="Vivaakam Logo"
//               style={{ width: '130px' }}
//             />
//           </div>
//         </Grid>
//       </Grid>
//       <Grid container spacing={2}>
//         <Grid item xs={6} sm={6} md={4} lg={4}>
//           <div className="lines">
//             <Link color="inherit" onClick={() => navigate('/contactus')}>
//               <h5 className="pointer">Contact Us</h5>
//             </Link>
//             <div className="iconbutton">
//               <EmailIcon style={{ width: '15px' }} />
//               <Typography variant="subtitle2" className="detailsfot">support@vivaakam.com</Typography>
//             </div>
//             <div className="iconbuttonphone">
//               {/* <PhoneIcon  style={{width:'15px'}}/> */}
//               <img src={phone} style={{ width: '20px' }} />
//               <Typography variant="subtitle2" className="detailsfot">+94 70 170 3996</Typography>
//             </div>
//             <div className="iconbutton">
//               <LocationOnIcon style={{ width: '15px' }} />
//               <Typography variant="subtitle2" className="detailsfot">No. 28 1-1, Temple Lane, Colombo 03, Sri Lanka</Typography>
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={0} sm={0} md={4} lg={4} sx={{ display: { md: "block", lg: "block", sm: "none", xs: "none" } }}>
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
//             <img
//               src={vivakamLogo}
//               alt="Vivaakam Logo"
//               style={{ width: '250px' }}
//             />
//           </div>
//         </Grid>
//         <Grid item xs={6} sm={6} md={4} lg={4}>
//           <div className="lines">
//             <h5>Support</h5>
//             <div className="lines2">
//               <div className="iconbutton">
//                 <Link color="inherit" onClick={() => navigate('/faq')}>
//                   <Typography className="pointer" variant="subtitle2">FAQs</Typography>
//                 </Link>
//               </div>
//               <div className="iconbutton">
//                 <Link color="inherit" onClick={() => navigate('/terms')}>
//                   <Typography className="pointer" variant="subtitle2">T & C</Typography>
//                 </Link>
//               </div>
//               <div className="iconbutton">
//                 <Link color="inherit" onClick={() => navigate('/policy')}>
//                   <Typography className="pointer" variant="subtitle2">Privacy Policy</Typography>
//                 </Link>
//               </div>
//               <div className="social-icons">
//                 <a href="https://www.linkedin.com/company/vivaakam/"><LinkedInIcon /></a>
//                 <a href="https://www.youtube.com/channel/UCuN7MutXznSWVURcMshU7PA"><YouTubeIcon /></a>
//                 <a href="https://www.facebook.com/profile.php?id=100092653369059"><FacebookIcon /></a>
//                 <a href="https://www.instagram.com/vivaakam/"><InstagramIcon /></a>
//                 <a href="https://www.tiktok.com/@vivaakam?lang=en"><i class="bi bi-tiktok"></i></a>
//                 <a href="https://twitter.com/vivaakam"><XIcon/></a>
//               </div>
//             </div>
//           </div>
//         </Grid>
//       </Grid>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={12} md={12} lg={12}>
//           <div className="powered" style={{ fontFamily: 'Dm Sans', display: 'flex', justifyContent: 'center', marginBottom: '20px', fontSize: '12px' }} color="textSecondary">
//             <div>
//               All Rights Reserved &copy; 2024 Vivaakam. Designed & developed by{' '}&nbsp;
//             </div>
//             <div>
//               <Link color="inherit" href="https://www.infinityinnovators.com/">
//                 Infinity Innovators (Pvt) Ltd
//               </Link>
//             </div>
//           </div>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };


// const App = () => {

//   const videoRef = useRef(null);

//   const handlePlayClick = () => {
//     if (videoRef.current) {
//       videoRef.current.play();
//     }
//   };

//   return (
//     <div>
//       <Header />
//       <CoverImage />
//       <BasicInfo />
//       <Footer />
//     </div>
//   );
// };

// export default App;

import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Card, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../../CSS/Homepage.css";
import SingleSignOn from './SingleSignOn.js';

const WelcomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsModalOpen(false);
  }, []);

  return (
    <Box className="welcome-container">
      <img src="https://matrimonyprofilestorage.blob.core.windows.net/images/image%20(2).png" className="welcome-srcn-logo" />
      <Box className="welcome-content">
        <Typography variant="h3" className="welcome-title">
          Welcome!
        </Typography>
        <Typography variant="subtitle1" className="welcome-subtitle">
          Click here to continue to your account
        </Typography>
        <Box className="progress-bar"></Box>
        <Card className="welcome-card">
          <CardMedia
            component="img"
            alt="Welcome Image"
            image="https://matrimonyprofilestorage.blob.core.windows.net/images/image.jpg"
            className="welcome-image"
          />
          {!isModalOpen ? (
            <Button
              variant="contained"
              className="sign-in-button"
              color="success"
              onClick={handleModalOpen}
            >
              SignIn / SignUp
            </Button>
          ) : (
            <SingleSignOn handleClose={handleModalClose} />
          )}
        </Card>
      </Box>
      <footer className="footer">
        © 2025 All Rights Reserved. Powered by Vivvakam. Developed by Infinity
        Innovators (Pvt) Ltd
      </footer>
    </Box>
  );
};

export default WelcomePage;

